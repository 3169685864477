import React, { useEffect, useState } from 'react';
import './modal.scss';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import client from '../../client';
import { useTranslation } from 'react-i18next';
import { readItem } from '@directus/sdk';

const List = (props) => {

    const { data } = props;

    const { t } = useTranslation(['config',]);

    if (data === "[[]]") return null;

    const ausbildungArrayToText = (arr) => {
        if (arr === '[]') return null;
        const parsedArray = JSON.parse(arr);
        return parsedArray.map((item) => {
            return <li>{t(item)}</li>
        })
    }

    return (
        <ul className="ausbildung">
            {ausbildungArrayToText(data)}
        </ul>
    );

}


const Modal = (props) => {
    const { title, children } = props;

    const [item, setItem] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation(['erfassen-seite',]);

    const { id } = useParams();

    const onClose = () => {
        navigate(-1)
    }

    const ausbildungArrayToText = (arr) => {
        if (arr === '[]') return null;
        const parsedArray = JSON.parse(arr);
        return parsedArray.map((item) => {
            return <li>{t(item)}</li>
        })
    }


    useEffect(() => {

        document.body.style.overflow = 'hidden';


        const fetchData = async () => {

            const data = await client.request(readItem('trainer_items', id));
            const tmpData = { ...data, date: new Date(data.date_created).toLocaleDateString('de-CH') };
            setItem(tmpData);


        }

        fetchData()
            .catch(console.error);

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [])

    if (!item) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>

            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className={'close-button'} onClick={onClose}><img src={process.env.PUBLIC_URL + '/assets/close.svg'} alt="arrow-filter" /></button>

                <div className="boerse-item two-columns trainerfinder" key={item.id}>

                    <div className="column image-column">

                        {(item?.image !== undefined && item?.image !== null) &&

                            <div className="image-wrapper">
                                <img src={`https://api.swiss-cycling-jobboerse.ch/assets/${item?.image}`} />

                            </div>

                        }
                    </div>

                    <div className="column">

                        <div>
                            <div className="boerse-item__tag boerse-item__tag--date">{item.date}</div>
                            <h2>{`${item?.vorname} ${item?.nachname}`}</h2>
                        </div>

                        {/* <div className="boerse-item__organisation">
                            {item.wohnkanton !== "" ? item.wohnkanton : null}
                        </div> */}

                        <h3>{t('Angebot')}</h3>
                        {(item?.angebot != null && item?.angebot?.includes('[')) ? <List data={item.angebot} /> : <div>{item?.disziplinen}</div>}

                        <h3>{t('Disziplinen')}</h3>
                        {(item?.disziplinen != null && item?.disziplinen?.includes('[')) ? <List data={item.disziplinen} /> : <div>{item?.disziplinen}</div>}

                        {
                            item.philosophie ?
                                <div>
                                    <h3>{t('Trainerphilosophie')}</h3>
                                    <p>{item.philosophie}</p>
                                </div> : null
                        }

                        <h3>{t('Erfahrung als Trainer/in')}</h3>
                        {(item?.erfahrung != null && item?.erfahrung?.includes('[')) ? <List data={item.erfahrung} /> : <div>{item?.erfahrung}</div>}


                        {
                            item.erfahrung_freitext ?
                                <ul className="ausbildung">
                                    <li>{item.erfahrung_freitext}</li>
                                </ul> : null
                        }

                        <h3>{t('Ausbildung')}</h3>
                        {(item?.ausbildung != null && item?.ausbildung?.includes('[')) ? <List data={item.ausbildung} /> : <div>{item?.disziplinen}</div>}




                        <h3>{t('Sprachen')}</h3>
                        {(item?.sprachen != null && item?.sprachen?.includes('[')) ? <List data={item.sprachen} /> : <div>{item?.sprachen}</div>}



                        <h3>{t('Kontakt')}</h3>
                        {/* <div>{item.vorname} {item.nachname}</div> */}

                        <div>
                            {item.email && item.email !== '' && (
                                <a href={"mailto:" + item.email}>{item.email}</a>
                            )}
                        </div>
                        <div>
                            {item.webseite && item.webseite !== '' && (
                                <a href={item.webseite} target="_blank" rel="noopener noreferrer">
                                    {item.webseite}
                                </a>
                            )}
                        </div>
                        <div>
                            {item.telefon && item.telefon !== '' && (
                                <a href={"tel:" + item.telefon}>{item.telefon}</a>
                            )}
                        </div>

                    </div>

                </div>

            </div >
        </div >
    );
};

Modal.defaultProps = {
    title: 'Modal Title'
}

export default Modal;
