const config = {
    item: {
        postType: 'unterlagen',
        category: null,
        searchPlaceholder: 'Unterlagen suchen',
        filterCategories: [
            {
                slug: 'disziplinen',
                labelKey: 'Disziplinen'
            },
            {
                slug: 'angebot',
                labelKey: 'Angebot'
            },
            {
                slug: 'erfahrung',
                labelKey: 'Erfahrung'
            },
            {
                slug: 'wohnkanton',
                labelKey: 'Wohnkanton'
            },
            {
                slug: 'sprachen',
                labelKey: 'Sprachen'
            }
        ],
    }
}

export default config;