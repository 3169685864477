import React, { useEffect, useState } from 'react';
import '../styles/components/checkboxes.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '../pages/addnew/Tooltip';

export default function Checkboxes(props) {

    const { items, dispatch, keyName, checkedState, tooltip } = props;

    const [booleanCheckedState, setCheckedState] = useState(
        new Array(items.length).fill(false)
    );

    const { t } = useTranslation('config');

    useEffect(() => {
        if (checkedState == null || checkedState.length === 0) return;
        items.forEach((item, index) => {
            if (checkedState.includes(item)) {
                booleanCheckedState[index] = true;
            }
        });




        setCheckedState(booleanCheckedState);
    }, [checkedState]);


    const handleOnChange = (position) => {
        const updatedCheckedState = booleanCheckedState.map((item, index) =>
            index === position ? !item : item
        );

        const values = booleanCheckedState.map((item, index) =>
            index === position ? !item : item
        );

        var mask = updatedCheckedState.map(item => item);
        var newlist = items.filter((item, i) => mask[i]);
        dispatch({
            type: 'UPDATE',
            value: newlist,
            key: keyName,
        })
        console.log(newlist);
        setCheckedState(updatedCheckedState);
    }

    const returnToolTip = (index) => {




        if (!tooltip) return null;


        if (index in tooltip) {
            return <Tooltip text={tooltip[index]} />
        }



        return null;
    }

    return (
        <ul className="ausbildung-list">
            {items.map((name, index) => {
                return (
                    <li key={index}>
                        <div className="ausbildung-list-item">
                            <div className="left-section">
                                <input
                                    type="checkbox"
                                    id={`custom-checkbox-${name}`}
                                    name={name}
                                    value={name}
                                    checked={booleanCheckedState[index]}
                                    onChange={() => handleOnChange(index)}
                                />
                                <label htmlFor={`custom-checkbox-${name}`}>{t(name)}</label>
                                {returnToolTip(index)}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    )
}


Checkboxes.defaultProps = {
    tooltip: null
}