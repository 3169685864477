import React from 'react';
import './tooltip.scss';
import { ReactComponent as InfoIcon } from './inco-icon.svg';

export default function Tooltip(props) {
    const { text } = props;
    return (
        <div class='tooltip'>
            <InfoIcon />
            <span class='tooltiptext tooltip-right'>{text}</span>
        </div>
    );
}
